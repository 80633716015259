import "../css/app.scss";
import "bootstrap";
import TomSelect from "tom-select";
import "tom-select/dist/css/tom-select.bootstrap4.css";


document.addEventListener('DOMContentLoaded', function () {
    var settings = {
        plugins: {
            clear_button: {
                title: 'Remove all selected options',
            },
            remove_button: {
                title: 'Remove this item',
            }
        },

    };
    document.querySelectorAll('.tom-select').forEach(function (el) {
        if (!el.tomselect) {
            new TomSelect(el, settings);
        }
    });
});